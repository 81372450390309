  import React, {useState, useEffect} from 'react';
  import axios from 'axios';
  import { Layout, Menu, Typography, Button, Input, Radio } from 'antd';
  import { InstagramOutlined, YoutubeOutlined } from '@ant-design/icons';
  import appStoreLogo from './assets/app-store-logo.svg'; // Adjust path as needed
  import googlePlayLogo from './assets/google-play-badge.png'; // Adjust path as needed
  import img1 from './assets/img1.png'
  import img2 from './assets/img2.png'
  import img3 from './assets/img3.png'
  import img4 from './assets/img4.png'
  import img5 from './assets/img5.png'
  import logo from './assets/logo.png'

  const { Header, Content, Footer } = Layout;
  const { Title, Text } = Typography;

  function App() {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const [promoCode, setPromoCode] = useState('');
    const [redemptionLink, setRedemptionLink] = useState('');
   


    const detectDevice = () => {
      const ua = navigator.userAgent;
      console.log(ua)
      if (/android/i.test(ua)) {
        return 'android';
      }
      if (/iPad|iPhone|Macintosh|iPod/.test(ua)) {
        return 'ios';
      }
      return 'unknown';
    };

    const [selectedDevice, setSelectedDevice] = useState(detectDevice());

    useEffect(() => {
      setSelectedDevice(detectDevice() === 'unknown' ? 'ios' : detectDevice());
    }, []);

    const handleRedeem = async () => {
      const device = detectDevice();
      if (device === 'unknown') {
        alert('Device not recognized. Please use an Android or iOS device.');
        return;
      }
    
      try {
        const response = await axios.post('/api/promo/redeem-promocode', { promocode: promoCode, device: selectedDevice });
        setRedemptionLink(response.data.redemptionUrl);
    
        // Directly navigate to the new URL
        window.location.href = response.data.redemptionUrl;
      } catch (error) {
        console.error('Error redeeming promo code:', error);
        alert('Failed to redeem promo code.');
      }
    };
    
    

    const updateMedia = () => {
      setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    });

    const responsiveImgStyle = isMobile ? {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '80%', // Adjust the width as needed
      marginBottom: '20px' // Adds bottom margin
    } : {};

    return (
      <Layout className="layout">
        <Header style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={logo} alt="Enshape Logo" style={{ borderRadius: 30, marginRight: 10 }} />
          <Title style={{ color: 'white'}}>Enshape</Title>
    </Header>
        <Content style={{ padding: '0 50px', marginTop: 64 }}>
          <div className="site-layout-content" style={{ textAlign: 'center' }}>
            <Title level={3}>Log & Track your dietary habits easier than ever</Title>
            <Button type="link" href="https://www.instagram.com/Enshape_official" target="_blank">
              <InstagramOutlined /> Follow us on Instagram
            </Button>
            <Button type="link" href="https://www.youtube.com/@enshape_official" target="_blank">
              <YoutubeOutlined /> Find us on Youtube
            </Button>
            <br />
            <br />
            <br />
            {/* 
            <div style={{ display: 'inline-block', textAlign: 'center' }}>
              <Title level={3}>Have a Promo Code?</Title>
              <Radio.Group 
              value={selectedDevice} 
              onChange={e => setSelectedDevice(e.target.value)}
              style={{ marginBottom: 16 }}
            >
              <Radio.Button value="ios">iOS</Radio.Button>
              <Radio.Button value="android">Android</Radio.Button>
            </Radio.Group>
            <br />
              <Input 
                placeholder="Enter Promo Code" 
                style={{ width: 200, marginRight: '10px' }} 
                value={promoCode}
                onChange={e => setPromoCode(e.target.value)}
              />
              <br />
              <br />
              <Button type="primary" onClick={handleRedeem}>Redeem</Button>
            </div>
            <br />
            <br />
            <br />*/}
            <br />
            <a href="https://apps.apple.com/us/app/enshape/id6474455430" target="_blank" rel="noopener noreferrer">
            <img src={appStoreLogo} alt="Download Enshape on the App Store" style={{ height: '50px', width: '200px', verticalAlign: 'middle' }}/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.chasec130.Enshape" target="_blank" rel="noopener noreferrer">
            <img src={googlePlayLogo} alt="Get Enshape on Google Play" style={{ height: '68px', width: '170px', verticalAlign: 'middle' }}/>
            </a>
            <br />
            <br />
            <a href="https://enshapeapp.com/privacy-policy" target="_blank" rel="noopener noreferrer">
              <Title level={4} style={{ textDecoration: 'underline' }}>Privacy Policy</Title>
            </a>
            <br />
            <br />
            <img src={img1} className="responsive-img" style={{width: '300px', borderRadius: 30, ...responsiveImgStyle}} alt="Enshape img" />
            <img src={img2} className="responsive-img" style={{width: '300px', borderRadius: 30, ...responsiveImgStyle}} alt="Enshape img" />
            <img src={img3} className="responsive-img" style={{width: '300px', borderRadius: 30, ...responsiveImgStyle}} alt="Enshape img" />
            <img src={img4} className="responsive-img" style={{width: '300px', borderRadius: 30, ...responsiveImgStyle}} alt="Enshape img" />
            <img src={img5} className="responsive-img" style={{width: '300px', borderRadius: 30, ...responsiveImgStyle}} alt="Enshape img" />
            <br />
            <br />
            <Title level={4}>Enshape's Philosophy</Title>
            <Text>Enshape was created to demystify the Health & Fitness industry. During many people's weight loss journey,
              they will often be exposed to many short-term fads. Those whom persist will find that it's not about
              adopting a temporary diet or fad. When starting a weight loss journey, a lifelong commitment must be made
              in order to see results. Enshape is here to provide guidance towards the key drivers of weight loss, primarily
              restricting caloric intake below that of the total daily energy expenditure. Enshape helps individuals track 
              key macronutrients with a focus on protein from the ground-up. This allows catering to not only individuals looking to
              lose weight, but those whom look to gain muscle.
            </Text>
            <br />
            <br />
            <Text>
              Enshape's competitors will often offer a more all-inclusive experience in which you may track exercises,
              have daily weigh-ins, and such. Enshape was designed to not include these features out of purpose. The reason is predominately
              phychological. Many people will experience discouragement when using a fully guided experience as soon as they deviate, or do not
              receive the result that was promised. Enshape is not here to provide assistance as a means to an end, but a holistically happier, healthier life - for life.
            </Text>
          </div>
          
        </Content>
        <Footer style={{ textAlign: 'center' }}>
        <a href="mailto:support@enshapeapp.com" target="_blank" rel="noopener noreferrer">
          <Title level={5} style={{ textDecoration: 'underline' }}>Support@enshapeapp.com</Title>
          </a>
          <Text type="secondary" style={{ fontSize: 'small' }}>App Store® and the App Store® logo is a registered trademark of Apple Inc.</Text>
          <br />
          <Text type="secondary" style={{ fontSize: 'small' }}>Google Play and the Google Play logo are trademarks of Google LLC.</Text>
        </Footer>
      </Layout>
    );
  }

  export default App;
